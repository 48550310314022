import React, { CSSProperties } from 'react';
import { AppExtensionSDK } from '@contentful/app-sdk';
import tokens from '@contentful/forma-36-tokens';
import {
  Typography,
  Paragraph,
  Button,
  Card,
  TextField,
  FieldGroup,
  CheckboxField,
  FormLabel,
} from '@contentful/forma-36-react-components';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableProvidedDraggableProps,
  DropResult,
} from 'react-beautiful-dnd';
import { WorkflowStateItem } from '../WorkflowStateItem';

import { STATES_PER_WORKFLOW_LIMIT } from '../constants';
import { ContentType, ContentTypeId } from '../../model/ContentType';
import { Workflow, WorkflowId } from '../../model/Workflow';
import { WorkflowInstances, WorkflowStatesRecord } from '../../model/ApplicationState';
import { WorkflowState, WorkflowStateId } from '../../model/WorkflowState';
import { DialogParams, DialogResponse } from '../Dialogs';

interface Props {
  workflow: Workflow;
  updateWorkflow: (workflow: Workflow) => void;
  updateWorkflowStates: (workflowStates: WorkflowStatesRecord) => void;
  toggleContentTypeSelection: (contentTypeId: ContentTypeId, workflowId: WorkflowId) => void;
  contentTypes: ContentType[];
  openDialog: AppExtensionSDK['dialogs']['openCurrentApp'];
  workflowStates: WorkflowStatesRecord;
  workflowInstances: WorkflowInstances;
}

function changeWorkflowName(updateWorkflow: Props['updateWorkflow'], workflow: Workflow) {
  return (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedName = e.target.value.trim();
    const updatedWorkflow = { ...workflow, name: updatedName };
    updateWorkflow(updatedWorkflow);
  };
}

function openWorkflowStateDialog(
  openDialog: Props['openDialog'],
  updateWorkflow: Props['updateWorkflow'],
  updateWorkflowStates: Props['updateWorkflowStates'],
  workflowStates: Props['workflowStates'],
  workflow: Props['workflow'],
  selectedWorkflowStateId?: WorkflowStateId,
  titleVerb: 'Add' | 'Edit' = 'Add'
) {
  return async () => {
    const params: DialogParams = {
      workflow,
      workflowStates,
      selectedWorkflowStateId,
    };
    const result: DialogResponse = await openDialog({
      position: 'top',
      minHeight: 320,
      title: `${titleVerb} Workflow State`,
      parameters: params,
    });

    if (!result) {
      return;
    }

    if (result.addedWorkflowId && !workflow.states.includes(result.addedWorkflowId)) {
      updateWorkflow({ ...workflow, states: workflow.states.concat([result.addedWorkflowId]) });
    }

    updateWorkflowStates(result.workflowStates);
  };
}

function removeStateFromWorkflow(
  updateWorkflow: Props['updateWorkflow'],
  workflowState: WorkflowState,
  workflow: Workflow
) {
  updateWorkflow({
    ...workflow,
    states: workflow.states.filter((wfsId) => wfsId !== workflowState.id),
  });
}

function reorderStates(updateWorkflow: Props['updateWorkflow'], workflow: Workflow) {
  return (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const updatedStates = workflow.states.concat();
    const [removed] = updatedStates.splice(result.source.index, 1);
    updatedStates.splice(result.destination.index, 0, removed);

    updateWorkflow({
      ...workflow,
      states: updatedStates,
    });
  };
}

const getItemStyle = (draggableStyle: DraggableProvidedDraggableProps['style']): CSSProperties => ({
  userSelect: 'none',
  margin: `0 0 ${tokens.spacingM} 0`,

  // styles we need to apply on draggables
  ...draggableStyle,
});

export function WorkflowBuilder({
  workflow,
  updateWorkflow,
  contentTypes,
  workflowStates,
  openDialog,
  updateWorkflowStates,
  toggleContentTypeSelection,
  workflowInstances,
}: Props) {
  const attachedStates = workflow.states.map((wfsId) => workflowStates[wfsId]).filter((x) => x);
  const maxStatesReached = attachedStates.length >= STATES_PER_WORKFLOW_LIMIT;
  return (
    <div data-test-id="workflow-builder">
      <Card className="workflow-flow">
        <div className="entry-created">
          <Typography>
            <Paragraph>Entry is created</Paragraph>
          </Typography>
        </div>
        <DragDropContext onDragEnd={reorderStates(updateWorkflow, workflow)}>
          <Droppable droppableId="state-list">
            {(provided) => (
              <div className="states" {...provided.droppableProps} ref={provided.innerRef}>
                <div className="line" />
                {attachedStates.map((wfState, index) => (
                  <Draggable key={wfState.id} draggableId={wfState.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(provided.draggableProps.style)}>
                        <WorkflowStateItem
                          workflowState={wfState}
                          showDragIcon
                          editWorkflowState={openWorkflowStateDialog(
                            openDialog,
                            updateWorkflow,
                            updateWorkflowStates,
                            workflowStates,
                            workflow,
                            wfState.id,
                            'Edit'
                          )}
                          removeWorkflowState={() =>
                            removeStateFromWorkflow(updateWorkflow, wfState, workflow)
                          }
                          removeLabel="Remove"
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Button
          buttonType="muted"
          icon="Plus"
          disabled={maxStatesReached}
          className="add-button"
          testId="add-button"
          onClick={openWorkflowStateDialog(
            openDialog,
            updateWorkflow,
            updateWorkflowStates,
            workflowStates,
            workflow
          )}
        />
        {attachedStates.length === 0 && (
          <Typography className="empty-state-examples">
            <Paragraph>Add your first workflow state!</Paragraph>
            <Paragraph>Examples: In Progress, In Review, Done</Paragraph>
          </Typography>
        )}
        {maxStatesReached && (
          <Typography>
            <Paragraph>
              You can only have {STATES_PER_WORKFLOW_LIMIT} states per workflow. If you'd like more
              states, please reach out using our feedback form.
            </Paragraph>
          </Typography>
        )}
      </Card>
      <div className="workflow-meta">
        <TextField
          required
          name="workflow-name"
          id="workflow-name"
          labelText="Name"
          value={workflow.name}
          width="full"
          testId="workflow-name"
          onChange={changeWorkflowName(updateWorkflow, workflow)}
          textInputProps={{
            placeholder: 'Name your workflow',
            maxLength: 100,
            type: 'text',
          }}
        />

        <div className="content-types">
          <FieldGroup>
            <Typography>
              <FormLabel htmlFor="test">Content Types</FormLabel>
              <Paragraph>Activate this workflow for content types.</Paragraph>
            </Typography>
            {contentTypes.map((ct) => (
              <CheckboxField
                labelText={ct.name}
                id={`ct-checkbox-${ct.name}`}
                testId="ct-checkbox"
                name="someOption"
                value={ct.sys.id}
                checked={workflowInstances[ct.sys.id] === workflow.id}
                onChange={() => toggleContentTypeSelection(ct.sys.id, workflow.id)}
                key={ct.sys.id}
              />
            ))}
          </FieldGroup>
        </div>
      </div>
    </div>
  );
}
