import {
  Card,
  Checkbox,
  FormLabel,
  List,
  ListItem,
  Paragraph,
  TextLink,
  Typography,
} from '@contentful/forma-36-react-components';
import React from 'react';
import { Preview } from './Preview';

interface Props {
  onChangeEAPTerms: () => void;
  EAPTerms: boolean;
}

export function PreInstallScreen({ onChangeEAPTerms, EAPTerms }: Props) {
  const eapLinkComponent = (
    <TextLink href="https://ctfl.io/workflow-app-eap" target="_blank" rel="noopener noreferrer">
      Early Access Program terms
    </TextLink>
  );

  return (
    <div className="body pre-install" data-test-id="preinstall">
      <div className="info">
        <div className="text">
          <Typography>
            <Paragraph>
              Do you enjoy working with Contentful, but wish you could create states for your
              content types that match your own company's workflows? With this app, you can do
              exactly that.
            </Paragraph>
            <List className="list">
              <ListItem>Create custom content states</ListItem>
              <ListItem>Work according to your own workflow</ListItem>
              <ListItem>Monitor content lifecycle in a custom dashboard</ListItem>
            </List>
            <br />
            <Paragraph>To install this app, you need to accept the {eapLinkComponent}.</Paragraph>
          </Typography>
        </div>
        <div className="preview">
          <Preview />
        </div>
      </div>
      <Card className="eap-check">
        <Checkbox
          labelText="eap-check"
          id="eap-check"
          checked={EAPTerms}
          onChange={onChangeEAPTerms}
        />{' '}
        <FormLabel htmlFor="eap-check">
          I have read and agree to the Contentful {eapLinkComponent}.
        </FormLabel>
      </Card>
    </div>
  );
}
