import React, { Component } from 'react';
import { AppExtensionSDK } from '@contentful/app-sdk';
import { WorkflowStateRepository } from '../../data/WorkflowStateRepository';
import {
  ApplicationState,
  WorkflowRecord,
  WorkflowStatesRecord,
} from '../../model/ApplicationState';
import { ContentType, ContentTypeId } from '../../model/ContentType';
import produce from 'immer';
import { isInvalidWorkflow, Workflow, WorkflowId } from '../../model/Workflow';
import { buildBlankWorkflow } from '../util';
import { DEFAULT_WORKFLOW_NAME } from '../constants';
import {
  hydrateWorkflowStateWithTag,
  isWorkflowStateNew,
  unprefixWorkflowStateName,
  WorkflowState,
  WorkflowStateIdPrefix,
} from '../../model/WorkflowState';
import { CustomAPIClient } from '../../api';
import ConfigScreen from './ConfigScreen';
import * as logger from '../../logger';
import { Tag } from '../../model/Tag';
import { StateColor } from '../../model/StateColor';
import { sample } from 'lodash';

interface ConfigurationScreenContainerProps {
  sdk: AppExtensionSDK;
  api: CustomAPIClient;
}

interface ConfigurationScreenContainerState {
  parameters: ApplicationState;
  isInstalled: boolean;
  checkedEAPTerms: boolean;
  contentTypes: ContentType[];
  unsavedChanges: boolean;
}

/** A function that looks at all current workflow states and removes any old states from workflows if the state doesn't exist anymore */
function cleanWorkflowDefinitions(
  workflowStates: WorkflowStatesRecord,
  workflowDefinitions: WorkflowRecord
) {
  const workflowStateIds = Object.keys(workflowStates);

  return Object.values(workflowDefinitions).reduce(
    (acc, wfd) => ({
      ...acc,
      [wfd.id]: {
        ...wfd,
        states: wfd.states.filter((s) => workflowStateIds.includes(s)),
      },
    }),
    {}
  );
}

export default class ConfigScreenContainer extends Component<
  ConfigurationScreenContainerProps,
  ConfigurationScreenContainerState
> {
  private workflowStateRepository: WorkflowStateRepository;

  constructor(props: ConfigurationScreenContainerProps) {
    super(props);

    this.state = {
      parameters: {
        workflowInstances: {},
        workflowStates: {},
        workflowDefinitions: {},
      },
      isInstalled: false,
      checkedEAPTerms: false,
      unsavedChanges: false,
      contentTypes: [],
    };

    props.sdk.app.onConfigure(this.onSave);
    props.sdk.app.onConfigurationCompleted(this.onAfterConfig);

    this.workflowStateRepository = new WorkflowStateRepository(props.api, this.state.parameters);
  }

  async componentDidMount() {
    const { sdk } = this.props;

    const [parameters, isInstalled, cts, alreadyExisitingWorkflowStates] = await Promise.all([
      sdk.app.getParameters<ApplicationState | null>(),
      sdk.app.isInstalled(),
      sdk.space.getContentTypes<ContentType>(),
      this.getAlreadyCreatedWorkflowStates(),
    ]);

    const contentTypes = cts?.items ?? [];

    try {
      if (parameters) {
        this.workflowStateRepository.hydrateAppState(parameters);

        for (const workflowStateDTO of Object.values(parameters.workflowStates)) {
          const workflowState = await this.workflowStateRepository.read(workflowStateDTO.id);
          // we only want to show workflow states that are not part
          // of a workflow already
          if (workflowState.id in alreadyExisitingWorkflowStates) {
            delete alreadyExisitingWorkflowStates[workflowState.id];
          }
          parameters.workflowStates[workflowState.id] = workflowState;
        }
        parameters.workflowStates = {
          ...parameters.workflowStates,
          ...alreadyExisitingWorkflowStates,
        };
      }
      this.state.parameters.workflowStates = alreadyExisitingWorkflowStates;
    } catch (e) {
      logger.error('Unable to load previous configuration because of', e);
      sdk.notifier.error(
        'Something went wrong while loading previous configuration. Try and save again'
      );
    }

    this.setState(
      produce(this.state, (draft: ConfigurationScreenContainerState) => {
        draft.contentTypes = contentTypes;
        draft.isInstalled = !!isInstalled;
        draft.parameters = parameters ?? this.state.parameters;
        draft.checkedEAPTerms = !!isInstalled;
      }),
      () => sdk.app.setReady()
    );
  }

  componentDidUpdate() {
    this.workflowStateRepository.hydrateAppState(this.state.parameters);
  }

  onChangeEAPTerms = (): void => {
    this.setState(
      produce(this.state, (draft) => {
        draft.checkedEAPTerms = !draft.checkedEAPTerms;
      })
    );
  };

  createWorkflowStateFromExisitingTag = (existingTag: Tag): WorkflowState => {
    const exisitingWorkflowState: WorkflowState = {
      name: unprefixWorkflowStateName(existingTag.name),
      id: existingTag.sys.id,
      description: '',
      color: sample(Object.values(StateColor)) as StateColor,
    };

    return hydrateWorkflowStateWithTag(exisitingWorkflowState, existingTag);
  };

  toggleContentTypeSelection = async (
    ctId: ContentTypeId,
    workflowId: WorkflowId
  ): Promise<void> => {
    return new Promise((accept) => {
      this.setState(
        produce(this.state, (draft) => {
          if (draft.parameters.workflowInstances[ctId]) {
            delete draft.parameters.workflowInstances[ctId];
          } else {
            draft.parameters.workflowInstances[ctId] = workflowId;
          }
          draft.unsavedChanges = true;
        }),
        accept
      );
    });
  };

  updateWorkflow = async (workflow: Workflow): Promise<void> => {
    return new Promise((accept) => {
      this.setState(
        produce(this.state, (draft) => {
          draft.parameters.workflowDefinitions[workflow.id] = workflow;
          draft.unsavedChanges = true;
        }),
        accept
      );
    });
  };

  updateWorkflowStates = async (
    workflowStates: WorkflowStatesRecord,
    unsavedChanges = true
  ): Promise<void> => {
    return new Promise((accept) => {
      this.setState(
        produce(this.state, (draft) => {
          draft.parameters.workflowStates = workflowStates;
          draft.parameters.workflowDefinitions = cleanWorkflowDefinitions(
            workflowStates,
            this.state.parameters.workflowDefinitions
          );
          draft.unsavedChanges = unsavedChanges;
        }),
        accept
      );
    });
  };

  getAlreadyCreatedWorkflowStates = async (): Promise<WorkflowStatesRecord> => {
    // fetch already created Tags
    const { items } = await this.props.api.readTags();

    let existingWorkflowStates: Record<string, WorkflowState> = items
      .filter((tag) => tag.sys.id.startsWith(WorkflowStateIdPrefix))
      .map((tag) => this.createWorkflowStateFromExisitingTag(tag))
      .reduce((acc, workflowState) => {
        return {
          ...acc,
          [workflowState.id]: workflowState,
        };
      }, {});

    return existingWorkflowStates;
  };

  onSave = async () => {
    if (!this.state.isInstalled) {
      if (!this.state.checkedEAPTerms) {
        this.props.sdk.notifier.error('You must agree to the Early Access Program terms');
        return false;
      }
      // IMPORTANT: add first initial workflow when app is installed
      await this.updateWorkflow(buildBlankWorkflow(DEFAULT_WORKFLOW_NAME));
    } else {
      const workflowDefinitions = Object.values(this.state.parameters.workflowDefinitions);

      const hasInvalidWorkflows = workflowDefinitions.find(isInvalidWorkflow);

      if (hasInvalidWorkflows) {
        logger.error('Found invalid workflow', hasInvalidWorkflows);
        // We may want to make this better
        this.props.sdk.notifier.error('All workflows must have a name');
        return false;
      }

      try {
        const { newWorkflowStatesRecord, newWorkflowStates } = await this.upsertWorkflowStates(
          workflowDefinitions
        );

        await this.deleteUnusedWorkflowStates(newWorkflowStates);

        await this.updateWorkflowStates(newWorkflowStatesRecord, false);
      } catch (e) {
        logger.error('Unable to save workflow states because of', e);

        // We definitely want to make this better.
        // User Interface does not expose a more detailed message for the error, we try to infer
        // TODO: the correct error is definitely present in the API, expose it in UI
        if (e.code === 'ValidationFailed') {
          this.props.sdk.notifier.error(
            'Cannot create multiple states with the same name. Check your tags and try again'
          );
        } else {
          this.props.sdk.notifier.error('Something went wrong');
        }

        return false;
      }
    }

    logger.info('Validation succeeded. New parameters: ', this.state.parameters);
    return {
      parameters: this.state.parameters || ({} as ApplicationState),
      targetState: {
        EditorInterface: Object.keys(this.state.parameters.workflowInstances).reduce(
          (acc, ctId) => ({
            ...acc,
            [ctId]: { sidebar: { position: 0 } },
          }),
          {}
        ),
      },
    };
  };

  private async upsertWorkflowStates(workflowDefinitions: Workflow[]) {
    const newWorkflowStatesRecord: WorkflowStatesRecord = {};
    const newWorkflowStates: WorkflowState[] = [];

    for (const workflow of workflowDefinitions) {
      const newCurrentWorkflowStates = await Promise.all(
        workflow.states.map((stateId) => {
          const state = this.state.parameters.workflowStates[stateId];

          return isWorkflowStateNew(state)
            ? this.workflowStateRepository.create(state)
            : this.workflowStateRepository.update(state);
        })
      );
      newWorkflowStates.push(...newCurrentWorkflowStates);
      newCurrentWorkflowStates.forEach((state) => (newWorkflowStatesRecord[state.id] = state));
    }

    return { newWorkflowStatesRecord, newWorkflowStates };
  }

  private async deleteUnusedWorkflowStates(newWorkflowStates: WorkflowState[]) {
    const allWorkflowStates = await this.workflowStateRepository.query();

    const isNewState = (state: WorkflowState) => !!newWorkflowStates.find((i) => i.id === state.id);
    const statesToDelete = allWorkflowStates.filter((state) => !isNewState(state));

    const deletes = statesToDelete.map((state) => this.workflowStateRepository.delete(state));

    await Promise.all(deletes);
  }

  onAfterConfig = async () => {
    const isInstalled = await this.props.sdk.app.isInstalled();
    this.setState(produce(this.state, () => ({ isInstalled, unsavedChanges: false })));
  };

  render() {
    const childProps = {
      sdk: this.props.sdk,
      contentTypes: this.state.contentTypes,
      parameters: {
        workflowStates: this.state.parameters.workflowStates,
        workflowDefinitions: this.state.parameters.workflowDefinitions,
        workflowInstances: this.state.parameters.workflowInstances,
      },
      isInstalled: this.state.isInstalled,
      unsavedChanges: this.state.unsavedChanges,
      checkedEAPTerms: this.state.checkedEAPTerms,
      onAfterConfig: () => this.onAfterConfig(),
      onSave: () => this.onSave(),
      updateWorkflow: (workflow: Workflow) => this.updateWorkflow(workflow),
      updateWorkflowStates: (workflowStates: WorkflowStatesRecord) =>
        this.updateWorkflowStates(workflowStates),
      toggleContentTypeSelection: (contentTypeId: ContentTypeId, workflowId: WorkflowId) =>
        this.toggleContentTypeSelection(contentTypeId, workflowId),
      onChangeEAPTerms: () => this.onChangeEAPTerms(),
    };

    return <ConfigScreen {...childProps} />;
  }
}
