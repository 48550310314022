import React, { useState } from 'react';
import { AppExtensionSDK } from '@contentful/app-sdk';
import { WorkflowBuilder } from './WorkflowBuilder';
import { WorkflowHeader } from './WorkflowHeader';
import { buildBlankWorkflow } from '../util';
import { HARD_WORKFLOW_LIMIT } from '../constants';
import { ContentType, ContentTypeId } from '../../model/ContentType';
import { Workflow, WorkflowId } from '../../model/Workflow';
import {
  WorkflowInstances,
  WorkflowRecord,
  WorkflowStatesRecord,
} from '../../model/ApplicationState';

interface Props {
  updateWorkflow: (workflowToUpdate: Workflow) => void;
  updateWorkflowStates: (workflowStates: WorkflowStatesRecord) => void;
  workflows: WorkflowRecord;
  workflowStates: WorkflowStatesRecord;
  contentTypes: ContentType[];
  openDialog: AppExtensionSDK['dialogs']['openCurrentApp'];
  toggleContentTypeSelection: (contentTypeId: ContentTypeId, workflowId: WorkflowId) => void;
  workflowInstances: WorkflowInstances;
}

export function WorkflowsConfig({
  workflows,
  updateWorkflow,
  contentTypes,
  openDialog,
  updateWorkflowStates,
  workflowStates,
  toggleContentTypeSelection,
  workflowInstances,
}: Props) {
  const [selectedWorkflowId, selectWorkflow] = useState(Object.keys(workflows)[0]);

  const addNewWorkflow = () => {
    if (Object.keys(workflows).length < HARD_WORKFLOW_LIMIT) {
      const blankWorkflow = buildBlankWorkflow('New Workflow');
      updateWorkflow(blankWorkflow);
      selectWorkflow(blankWorkflow.id);
    }
  };

  return (
    <div className="body post-install">
      <WorkflowHeader
        workflows={workflows}
        selectWorkflow={selectWorkflow}
        addNewWorkflow={addNewWorkflow}
        selectedWorkflowId={selectedWorkflowId}
      />
      <WorkflowBuilder
        workflow={workflows[selectedWorkflowId]}
        workflowStates={workflowStates}
        updateWorkflow={updateWorkflow}
        contentTypes={contentTypes}
        openDialog={openDialog}
        updateWorkflowStates={updateWorkflowStates}
        toggleContentTypeSelection={toggleContentTypeSelection}
        workflowInstances={workflowInstances}
      />
    </div>
  );
}
