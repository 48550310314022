import {
  CollectionResponse,
  PageExtensionSDK,
  SearchQuery,
  SidebarExtensionSDK,
} from '@contentful/app-sdk';
import { Entry } from '../model/Entry';
import { Repository } from './Repository';
import { WorkflowStateId, WorkflowState, WorkflowStateIdPrefix } from '../model/WorkflowState';
import { toLink } from '../model/Tag';
import { NUMBER_OF_ENTITIES_PER_VIEW } from '../components/Pagination/constants';

export class EntryRepository implements Partial<Repository<Entry>> {
  constructor(private appSdk: PageExtensionSDK | SidebarExtensionSDK) {}

  async read(entryId: string) {
    return this.appSdk.space.getEntry<Entry>(entryId);
  }

  async readByWorkflowIds(
    workflowStateIds: WorkflowStateId[],
    query: SearchQuery = {}
  ): Promise<CollectionResponse<Entry>> {
    return this.appSdk.space.getEntries({
      'metadata.tags.sys.id[in]': workflowStateIds.join(','),
      limit: NUMBER_OF_ENTITIES_PER_VIEW,
      ...query,
    });
  }

  async updateWorkflowState(entryId: string, workflowState: WorkflowState): Promise<boolean> {
    const entry = await this.read(entryId);

    if (!entry || !workflowState.tag) {
      return false;
    }

    return this.appSdk.space.updateEntry({
      ...entry,
      metadata: {
        tags: entry.metadata.tags
          .filter((t) => !t.sys.id.startsWith(WorkflowStateIdPrefix))
          .concat([toLink(workflowState.tag)]),
      },
    });
  }
}
