import { Tag, TagLink } from './model/Tag';

export interface TagsAPIClient {
  readTags(skip?: number, limit?: number): Promise<ArrayResponse<Tag>>;
  createTag(id: string, name: string, version: number): Promise<Tag>;
  updateTag(id: string, name: string, version: number): Promise<Tag>;
  deleteTag(id: string, version: number): Promise<boolean>;
}

export interface Channel<T> {
  send(event: string, ...args: Array<any>): Promise<any>;
  call(channelName: T, methodName: string, args?: Array<any>): Promise<any>;
  addHandler<V>(channelName: T, callback: (value: V) => void): void;
}

export interface ArrayResponse<T> {
  items: Array<T>;
  sys: {
    type: 'Array';
  };
  total: number;
}

export interface Metadata {
  tags: TagLink[];
}

const CALL_SPACE_METHOD_CHANNEL = 'callSpaceMethod';
const METADATA_CHANGED_CHANNEL = 'metadataChanged';

export class CustomAPIClient implements TagsAPIClient {
  private metadata: Metadata = { tags: [] };
  private metadataListeners: ((metadata: Metadata) => void)[] = [];

  constructor(
    private channel: Channel<typeof CALL_SPACE_METHOD_CHANNEL | typeof METADATA_CHANGED_CHANNEL>
  ) {
    this.channel = channel;

    channel.addHandler<Metadata>(METADATA_CHANGED_CHANNEL, (newMetadata) => {
      this.metadata = {
        ...newMetadata,
        tags: [...newMetadata?.tags],
      };
      this.metadataListeners.forEach((listener) => listener(this.metadata));
    });
  }

  static create(
    channel: Channel<typeof CALL_SPACE_METHOD_CHANNEL | typeof METADATA_CHANGED_CHANNEL>
  ) {
    return new CustomAPIClient(channel);
  }

  createTag(id: string, name: string, version: number): Promise<Tag> {
    return this.channel.call(CALL_SPACE_METHOD_CHANNEL, 'createTag', [id, name, version]);
  }

  deleteTag(id: string, version: number): Promise<boolean> {
    return this.channel.call(CALL_SPACE_METHOD_CHANNEL, 'deleteTag', [id, version]);
  }

  readTags(skip?: number, limit?: number): Promise<ArrayResponse<Tag>> {
    return this.channel.call(CALL_SPACE_METHOD_CHANNEL, 'readTags', [skip, limit]);
  }

  updateTag(id: string, name: string, version: number): Promise<Tag> {
    return this.channel.call(CALL_SPACE_METHOD_CHANNEL, 'updateTag', [id, name, version]);
  }

  getMetadata(): Metadata {
    return this.metadata;
  }

  onMetadataChanged(listener: (metadata: Metadata) => void) {
    this.metadataListeners.push(listener);
    listener(this.metadata);
    return () => this.metadataListeners.splice(this.metadataListeners.indexOf(listener), 1);
  }
}
