import React from 'react';
import { Tabs, Tab, TextLink, Tooltip, Icon } from '@contentful/forma-36-react-components';
import './styles.scss';
import { WorkflowRecord } from '../../model/ApplicationState';

interface Props {
  workflows: WorkflowRecord;
  selectedWorkflowId: string;
  selectWorkflow: (workflowId: string) => void;
  addNewWorkflow: () => void;
}

export function WorkflowHeader({
  workflows,
  selectWorkflow,
  addNewWorkflow,
  selectedWorkflowId,
}: Props) {
  const workflowsList = Object.entries(workflows);
  return (
    <div className="workflow-header">
      <Tabs withDivider className="tabs" testId="workflow-tabs">
        {workflowsList.map(([workflowId, workflow]) => (
          <Tab
            key={workflowId}
            id={workflowId}
            selected={selectedWorkflowId === workflowId}
            onSelect={() => selectWorkflow(workflowId)}>
            {workflow.name}
          </Tab>
        ))}
      </Tabs>
      <Tooltip
        containerElement="div"
        content="Need more workflows? Reach out using our feedback form."
        id="tip1"
        place="bottom"
        targetWrapperClassName="tooltip">
        <TextLink onClick={addNewWorkflow} className="add-button">
          <Icon icon="Plus" className="plus-icon" />
          Add Workflow
        </TextLink>
      </Tooltip>
    </div>
  );
}
