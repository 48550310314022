import { CustomAPIClient } from '../api';
import {
  buildWorkflowStateId,
  hydrateWorkflowStateWithTag,
  prefixWorkflowStateName,
  WorkflowState,
  WorkflowStateIdPrefix,
} from '../model/WorkflowState';
import { Repository } from './Repository';
import { ApplicationState } from '../model/ApplicationState';
import { Tag } from '../model/Tag';
import { notEmpty } from '../components/util';

export class WorkflowStateRepository implements Repository<WorkflowState> {
  constructor(private apiClient: CustomAPIClient, private appState: ApplicationState) {}

  hydrateAppState(appState: ApplicationState) {
    this.appState = appState;
  }

  async query(): Promise<Array<WorkflowState>> {
    const tags = await this.apiClient.readTags();

    return tags.items
      .filter((tag) => tag.sys.id.startsWith(WorkflowStateIdPrefix))
      .map((tag) => {
        const workflowState = this.appState.workflowStates[tag.sys.id];

        if (!workflowState) {
          return null;
        }

        return hydrateWorkflowStateWithTag(workflowState, tag);
      })
      .filter(notEmpty);
  }

  async read(entityId: string): Promise<WorkflowState> {
    const workflowsStates = await this.query();

    const workflowState = workflowsStates.find((i) => i.id === entityId);

    if (!workflowState) {
      throw new ReferenceError(`WorkflowState ${entityId} is missing. Unable to proceed`);
    }

    return workflowState;
  }

  async readMany(entityIds: string[]): Promise<WorkflowState[]> {
    const allWorkflowsStates = await this.query();

    const workflowStates = entityIds.map((entityId) =>
      allWorkflowsStates.find((i) => i.id === entityId)
    );

    if (workflowStates.includes(undefined)) {
      throw new ReferenceError(`One ore more WorkflowStates are missing. Unable to proceed`);
    }

    return workflowStates as WorkflowState[];
  }

  async create(workflowState: WorkflowState): Promise<WorkflowState> {
    const tag: Tag = await this.apiClient.createTag(
      buildWorkflowStateId(workflowState.id),
      prefixWorkflowStateName(workflowState.name),
      1
    );

    return hydrateWorkflowStateWithTag(workflowState, tag);
  }

  async update(workflowState: WorkflowState): Promise<WorkflowState> {
    const oldTag = workflowState.tag;

    if (!oldTag) {
      throw new ReferenceError(
        `WorkflowState ${workflowState.id} is missing Tag. Unable to proceed`
      );
    }

    const tag: Tag = await this.apiClient.updateTag(
      buildWorkflowStateId(workflowState.id),
      prefixWorkflowStateName(workflowState.name),
      oldTag.sys.version
    );
    return hydrateWorkflowStateWithTag(workflowState, tag);
  }

  async delete(workflowState: WorkflowState) {
    const oldTag = workflowState.tag;

    if (!oldTag) {
      throw new ReferenceError(
        `WorkflowState ${workflowState.id} is missing Tag. Unable to proceed`
      );
    }

    // Best effort delete (only works if no Entries have the tag)
    try {
      this.apiClient.deleteTag(workflowState.id, oldTag.sys?.version ?? 1);
    } catch {}
    return true;
  }
}
