import { SidebarExtensionSDK } from '@contentful/app-sdk';
import React from 'react';
import { CustomAPIClient } from '../../api';
import { EntryRepository } from '../../data/EntryRepository';
import { WorkflowStateRepository } from '../../data/WorkflowStateRepository';
import { ApplicationState } from '../../model/ApplicationState';
import { getWorkflowStateIdFromMetadata } from '../../model/Entry';
import { Workflow } from '../../model/Workflow';
import { WorkflowState, WorkflowStateId } from '../../model/WorkflowState';
import { Sidebar } from './Sidebar';
import { Note, TextLink } from '@contentful/forma-36-react-components';

interface Props {
  sdk: SidebarExtensionSDK;
  api: CustomAPIClient;
}

interface State {
  selectedId: WorkflowStateId;
  workflow?: Workflow;
  workflowStates: WorkflowState[];
  loading: boolean;
  // This can be an enum, if it needs be
  error: boolean;
}

export class SidebarContainer extends React.Component<Props, State> {
  private workflowStateRepo: WorkflowStateRepository;
  private entryRepo: EntryRepository;

  private metadataUnsubscribe: (() => void) | undefined;

  constructor(props: Props) {
    super(props);

    const params = this.props.sdk.parameters.installation as ApplicationState;
    const { workflowInstances, workflowDefinitions } = params;
    const workflow = workflowDefinitions[workflowInstances[this.props.sdk.contentType.sys.id]];

    this.workflowStateRepo = new WorkflowStateRepository(props.api, params);
    this.entryRepo = new EntryRepository(props.sdk);

    this.state = {
      workflow,
      selectedId: '',
      workflowStates: [],
      loading: true,
      error: false
    };
  }

  async componentDidMount() {
    if (!this.state.workflow) {
      this.setState({ error: true });
      return;
    }

    try {
      this.setState({ loading: true });

      this.props.sdk.window.updateHeight();

      const workflowStates = await this.workflowStateRepo.readMany(this.state.workflow.states);

      const selectedWorkflowStateId = getWorkflowStateIdFromMetadata(this.props.api.getMetadata());
      this.setState({ workflowStates, selectedId: selectedWorkflowStateId, loading: false });

      this.props.sdk.window.updateHeight();

      this.metadataUnsubscribe = this.props.api.onMetadataChanged((metadata) => {
        this.setState({
          selectedId: getWorkflowStateIdFromMetadata(metadata)
        });
      });
    } catch (e) {
      console.error(e);
      this.setState({ error: true });
    }
  }

  componentWillUnmount() {
    this.metadataUnsubscribe?.();
  }

  componentDidUpdate() {
    this.props.sdk.window.updateHeight();
  }

  selectWorkflowStateId = (id: WorkflowStateId) => {
    const selectedState = this.state.workflowStates.find((wfs) => wfs.id === id);

    if (selectedState) {
      const previousSelectedId = this.state.selectedId;
      this.setState({ selectedId: id }, async () => {
        const updated = await this.entryRepo.updateWorkflowState(
          this.props.sdk.ids.entry,
          selectedState
        );

        if (!updated) {
          this.setState({ selectedId: previousSelectedId });
          this.props.sdk.notifier.error(
            'There was a problem updating the workflow state. Please refresh and try again.'
          );
        }
      });
    } else {
      this.setState({ selectedId: '' });
      this.props.sdk.notifier.error(
        'A problem occurred while updating the workflow state. Please try again.'
      );
    }
  };

  render() {
    const spaceId = this.props.sdk.ids.space;
    if (this.state.error) {
      const environmentId = this.props.sdk.ids.environment;
      return (
        <Note noteType="warning" testId="cf-ui-workflows-sidebar-warning">
          Entries of this content type are not assigned to a workflow.
          <br/>
          <br/>
          <TextLink
            href={`https://launch.contentful.com/spaces/${spaceId}/environments/${environmentId}/workflows/config`}
            target="_blank" rel="noopener noreferrer">
            Configure workflow
          </TextLink>
        </Note>
      );
    }

    return (
      <Sidebar
        isLoading={this.state.loading}
        selectedWorkflowStateId={this.state.selectedId}
        workflowStates={this.state.workflowStates}
        navigateToDashboard={() => this.props.sdk.navigator.openCurrentAppPage()}
        selectWorkflowState={this.selectWorkflowStateId}
        spaceId={spaceId}
        updateHeight={this.props.sdk.window.updateHeight}
      />
    );
  }
}
