import { EntrySys, CollectionResponse, User, ContentType } from '@contentful/app-sdk';
import { WorkflowStateId, WorkflowState, ALL_WORKFLOW_STATE } from './WorkflowState';
import { StateColor, InternalStateColor } from './StateColor';
import { UserId } from './User';
import { TagLink } from './Tag';
import { WorkflowStateIdPrefix } from './WorkflowState';
import { get, isObject, isString } from 'lodash';
import { Metadata } from '../api';

export type WorkflowStateEntryRecord = Record<WorkflowStateId, WorkflowEntries>;

export interface WorkflowEntries {
  color?: StateColor | InternalStateColor;
  id: WorkflowStateId;
  name: string;
  entries: CollectionResponse<Entry>;
}

export declare type Status = 'published' | 'draft' | 'archived' | 'changed';

export interface APIEntry {
  fields: {
    [key: string]: {
      [key: string]: string;
    };
  };
  metadata: {
    tags: TagLink[];
  };
  sys: EntrySys;
}

export interface Entry extends APIEntry {
  author: string;
  title: string;
  contentTypeName?: string;
}

export const buildWorkflowStateEntry = (
  currentUser: User,
  apiEntries: CollectionResponse<APIEntry>,
  usersOfSpace: User[],
  contentTypes: ContentType[],
  workflowState: WorkflowState = ALL_WORKFLOW_STATE
): WorkflowStateEntryRecord => {
  const entries: Entry[] = apiEntries.items.map((entry) => {
    const {
      sys: {
        contentType: {
          sys: { id: contentTypeId },
        },
        createdBy: {
          sys: { id },
        },
      },
    } = entry;

    const contentType = contentTypes.find((contentType) => contentType.sys.id === contentTypeId);
    const author = resolveAuthorOfEntry(id, currentUser, usersOfSpace);

    const title = getEntryTitle({
      entry,
      defaultLocaleCode: 'en-US',
      defaultTitle: 'Untitled',
      contentType,
    });

    return {
      author,
      title,
      sys: entry.sys,
      fields: entry.fields,
      metadata: entry.metadata,
      contentTypeName: contentType?.name,
    };
  });

  return {
    [workflowState.id]: {
      id: workflowState.id,
      color: workflowState.color,
      name: workflowState.name,
      entries: {
        ...apiEntries,
        items: entries,
      },
    },
  };
};

export const resolveAuthorOfEntry = (
  userId: UserId,
  currentUser: User,
  allUsersOfSpace: User[]
): string => {
  const user = allUsersOfSpace.find((user) => user.sys.id === userId);

  if (!user) {
    return 'Unknown';
  }

  if (user.sys.id === currentUser.sys.id) {
    return 'Me';
  }

  return `${user.firstName} ${user.lastName}`;
};

export const getEntryStatus = (entrySys: EntrySys): Status => {
  if (!!entrySys.archivedVersion) {
    return 'archived';
  } else if (!!entrySys.publishedVersion && entrySys.version === entrySys.publishedVersion + 1) {
    return 'published';
  } else if (!!entrySys.publishedVersion && entrySys.version >= entrySys.publishedVersion + 2) {
    return 'changed';
  }
  return 'draft';
};

function titleOrDefault(title: string | undefined, defaultTitle: string): string {
  if (!isString(title)) {
    return defaultTitle;
  }
  if (title) {
    const trimmedTitle = title.trim();
    if (trimmedTitle.length === 0) {
      return defaultTitle;
    }
    return trimmedTitle;
  }
  return defaultTitle;
}

function getFieldValue({
  /**
   * Expects an entity fetched with a flag Skip-Transformation: true
   */
  entity,
  fieldId,
  defaultLocaleCode,
}: {
  entity: {
    fields: { [key: string]: { [valueKey: string]: string | undefined } };
  };
  fieldId: string;
  defaultLocaleCode: string;
}): string | undefined {
  const values = get(entity, ['fields', fieldId]);
  if (!isObject(values)) {
    return;
  }

  const firstLocaleCode = Object.keys(values)[0];

  return values[defaultLocaleCode] || values[firstLocaleCode];
}

export function getEntryTitle({
  entry,
  contentType,
  defaultLocaleCode,
  defaultTitle,
}: {
  entry: APIEntry;
  contentType?: ContentType;
  defaultLocaleCode: string;
  defaultTitle: string;
}) {
  let title;

  if (!contentType) {
    return defaultTitle;
  }

  const displayField = contentType.displayField;
  if (!displayField) {
    return defaultTitle;
  }

  const displayFieldInfo = contentType.fields.find((field) => field.id === displayField);

  if (!displayFieldInfo) {
    return defaultTitle;
  }

  // when localization for a field is "turned off",
  // we don't clean up the "old" localized data, so it is still in the response.
  // Therefore, we're checking if displayField is localizable.
  if (displayFieldInfo.localized) {
    title = getFieldValue({
      entity: entry,
      fieldId: displayField,
      defaultLocaleCode,
    });

    if (!title) {
      // Older content types may return id/apiName, but some entry lookup paths do not fetch raw data
      // In order to still return a title in this case, look for displayField as apiName in content type,
      // ...but still look for displayField as a field in the entry
      title = getFieldValue({
        entity: entry,
        fieldId: displayFieldInfo.id,
        defaultLocaleCode,
      });
    }
  } else {
    title = getFieldValue({
      entity: entry,
      fieldId: displayField,
      defaultLocaleCode,
    });
    if (!title) {
      title = getFieldValue({
        entity: entry,
        fieldId: displayFieldInfo.id,
        defaultLocaleCode,
      });
    }
  }

  return titleOrDefault(title, defaultTitle);
}

export function getWorkflowStateIdFromMetadata(metadata: Metadata): string {
  const tagLinks = metadata.tags.filter((t: TagLink) => t.sys.id.startsWith(WorkflowStateIdPrefix));

  // there should never be more than one workflow state tag present
  if (!tagLinks || tagLinks.length > 1) {
    return '';
  }

  return tagLinks[0]?.sys.id || '';
}
