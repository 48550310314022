import { css } from 'emotion';

export const styles = {
  preview: css({
    width: '330px',
    position: 'absolute',
    top: '-60px',
    left: '60px',
  }),
};
