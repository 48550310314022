import React, { useEffect } from 'react';
import { Note } from '@contentful/forma-36-react-components'
import { AppExtensionSDK } from '@contentful/app-sdk';

type Props = {
  sdk: AppExtensionSDK
}

const EAPWarning = (props?: Props) => {
  useEffect(() => {
    props?.sdk.app?.setReady();
  })

  return (
    <Note noteType="primary" title="Early access program ended" className="eap-warning">Thank you for participating in the early access program of the Workflows app! Its functionality can soon be found in a new product offering from Contentful. Please contact your customer success manager to find out more.</Note>
  )
}

export default EAPWarning;
