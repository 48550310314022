import {
  Button,
  Paragraph,
  SkeletonContainer,
  SkeletonDisplayText,
  Typography,
} from '@contentful/forma-36-react-components';
import tokens from '@contentful/forma-36-tokens';
import React from 'react';
import { WorkflowState } from '../../model/WorkflowState';
import { styles } from './styles';

interface Props {
  toggleMenuOpen(state: boolean): void;
  menuOpen: boolean;
  workflowState: WorkflowState;
  isBlankState: boolean;
  isLoadingState: boolean;
}

export function SelectedWorkflowState({
  toggleMenuOpen,
  menuOpen,
  workflowState,
  isBlankState,
  isLoadingState,
}: Props) {
  return (
    <Button
      onClick={() => (isLoadingState ? null : toggleMenuOpen(!menuOpen))}
      isFullWidth
      indicateDropdown
      buttonType="muted"
      className={styles.sidebarSelected}
      testId="selected-workflow">
      <div className={styles.sidebarInfo}>
        <div
          className={styles.stateColor}
          style={{
            width: isLoadingState ? '14px' : '',
            backgroundColor: isBlankState ? tokens.colorElementMid : workflowState.color,
          }}
        />
        {isLoadingState ? (
          <div style={{ height: '32px' }}>
            <SkeletonContainer height="16">
              <SkeletonDisplayText numberOfLines={1} offsetTop={4} />
            </SkeletonContainer>
          </div>
        ) : (
          <Typography className={styles.nameDescription}>
            <Paragraph>{workflowState.name}</Paragraph>
            <Paragraph>{workflowState.description}</Paragraph>
          </Typography>
        )}
      </div>
    </Button>
  );
}
