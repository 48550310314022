import { ContentType, PageExtensionSDK, SidebarExtensionSDK } from '@contentful/app-sdk';
import { Repository } from './Repository';

export class ContentTypeRepository implements Partial<Repository<ContentType>> {
  constructor(private appSdk: PageExtensionSDK | SidebarExtensionSDK) {}

  async query() {
    const { items } = await this.appSdk.space.getContentTypes<ContentType>();
    return items;
  }
}
