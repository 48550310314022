import React from 'react';
import { render } from 'react-dom';

import {
  AppExtensionSDK,
  PageExtensionSDK,
  SidebarExtensionSDK,
  DialogExtensionSDK,
  KnownSDK,
  init,
  locations,
} from '@contentful/app-sdk';
import '@contentful/forma-36-react-components/dist/styles.css';
import '@contentful/forma-36-fcss/dist/styles.css';
import './index.scss';

import { WorkflowsDashboard } from './components/WorkflowsDashboard';
import { ConfigScreen } from './components/ConfigScreen';
import { SidebarContainer } from './components/Sidebar';
import Dialog from './components/Dialog';
import { CustomAPIClient } from './api';
import { PRODUCTION_APP_ID } from './components/constants';
import EAPWarning from './components/EAPWarning';

init(
  // expect error because currently our types don't allow customAPIClient
  // @ts-expect-error
  (sdk: KnownSDK, customAPIClient: CustomAPIClient) => {
    const root = document.getElementById('root');

    // Disable independent app instance after EAP program (should only be available inside Launch App)
    if(sdk.ids.app === PRODUCTION_APP_ID) {
      render(<EAPWarning sdk={sdk as AppExtensionSDK} />, root);
    } else {
      const ComponentLocationSettings = [
        {
          location: locations.LOCATION_APP_CONFIG,
          component: <ConfigScreen sdk={sdk as AppExtensionSDK} api={customAPIClient} />,
        },
        {
          location: locations.LOCATION_DIALOG,
          component: <Dialog sdk={sdk as DialogExtensionSDK} />,
        },
        {
          location: locations.LOCATION_ENTRY_SIDEBAR,
          component: <SidebarContainer sdk={sdk as SidebarExtensionSDK} api={customAPIClient} />,
        },
        {
          location: locations.LOCATION_PAGE,
          component: <WorkflowsDashboard sdk={sdk as PageExtensionSDK} />,
        },
      ];

      ComponentLocationSettings.forEach(componentLocationSetting => {
        if (sdk.location.is(componentLocationSetting.location)) {
          render(componentLocationSetting.component, root);
        }
      });
    }
  },
  // @ts-ignore
  { makeCustomApi: CustomAPIClient.create }
);
