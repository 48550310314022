import { EntrySys } from '@contentful/app-sdk';
import {
  Paragraph,
  SkeletonBodyText,
  SkeletonContainer,
  SkeletonRow,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tag,
  Typography,
  Workbench,
} from '@contentful/forma-36-react-components';
import tokens from '@contentful/forma-36-tokens';
import { css } from 'emotion';
import React, { useMemo } from 'react';
import { Status, WorkflowStateEntryRecord } from '../../model/Entry';
import { WorkflowStateId } from '../../model/WorkflowState';
import Pagination from '../Pagination';
import { NUMBER_OF_ENTITIES_PER_VIEW } from '../Pagination/constants';
import './styles.scss';
import { WorkflowSidebarItem } from './WorkflowSidebarItem';

interface Props {
  workflowStates: WorkflowStateEntryRecord;
  workflowStateIds: WorkflowStateId[];
  loading: boolean;
  entriesLoading: boolean;
  setActiveWorkflowState: (workflowStateId: WorkflowStateId) => void;
  getEntryStatus: (entrySys: EntrySys) => Status;
  handlePageChange: (page: number, workflowStateId: WorkflowStateId) => void;
  activePage: number;
  activeWorkflowState: WorkflowStateId;
  openEntry(id: string): void;
}

const Page = ({
  workflowStates,
  workflowStateIds,
  loading,
  getEntryStatus,
  setActiveWorkflowState,
  activeWorkflowState,
  handlePageChange,
  activePage,
  entriesLoading,
  openEntry,
}: Props) => {
  const isEmpty =
    !loading && !entriesLoading && !workflowStates[activeWorkflowState].entries.items.length;

  const allWorkflowStateIds = useMemo(() => {
    return ['all', ...workflowStateIds]; // add fake 'all' state
  }, [workflowStateIds]);

  return (
    <Workbench className="workbench">
      <Workbench.Sidebar className="sidebar-workbench">
        {loading
          ? Array.from(Array(5)).map((_, rowIndex) => (
              <SkeletonContainer key={rowIndex} svgHeight={37}>
                <SkeletonBodyText numberOfLines={1} />
              </SkeletonContainer>
            ))
          : allWorkflowStateIds.map((wfsId) => {
              const {
                name,
                id,
                color,
                entries: { total },
              } = workflowStates[wfsId];
              return (
                <WorkflowSidebarItem
                  key={id}
                  amount={total}
                  name={name}
                  isActive={activeWorkflowState === id}
                  onClick={() => setActiveWorkflowState(wfsId)}
                  color={color}
                />
              );
            })}
      </Workbench.Sidebar>
      <Workbench.Content>
        {isEmpty ? (
          <Typography>
            <Paragraph>No entries are currently in this state.</Paragraph>
          </Typography>
        ) : (
          <Table className={css({ marginBottom: tokens.spacingM })}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Content type</TableCell>
                <TableCell>Updated</TableCell>
                <TableCell>Author</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading || entriesLoading ? (
                <SkeletonRow rowCount={5} columnCount={5} />
              ) : (
                workflowStates[activeWorkflowState].entries.items.map((entry) => {
                  return (
                    <TableRow
                      key={entry.sys.id}
                      style={{ cursor: 'pointer' }}
                      // @ts-ignore ignores onClick not being a prop even though it is
                      onClick={() => openEntry(entry.sys.id)}>
                      <TableCell>{entry.title}</TableCell>
                      <TableCell>
                        {entry.contentTypeName ?? entry.sys.contentType.sys.id}
                      </TableCell>
                      <TableCell>{new Date(entry.sys.updatedAt).toDateString()}</TableCell>
                      <TableCell>{entry.author}</TableCell>
                      <TableCell>
                        {
                          <Tag entityStatusType={getEntryStatus(entry.sys)}>
                            {getEntryStatus(entry.sys)}
                          </Tag>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        )}
        {workflowStates[activeWorkflowState].entries.total > NUMBER_OF_ENTITIES_PER_VIEW ? (
          <Pagination
            itemsPerPage={NUMBER_OF_ENTITIES_PER_VIEW}
            onPageChange={(page) => handlePageChange(page, workflowStates[activeWorkflowState].id)}
            totalItems={workflowStates[activeWorkflowState].entries.total}
            activePage={activePage}
          />
        ) : null}
      </Workbench.Content>
    </Workbench>
  );
};

export default Page;
