import React from 'react';
import { Button } from '@contentful/forma-36-react-components';
import clamp from 'lodash/clamp';
import range from 'lodash/range';
import { doubleChevron } from './doubleChevron';
import './styles.scss';

export interface Props {
  activePage: number;
  className?: string;
  itemsPerPage: number;
  totalItems: number;
  onPageChange: (page: number) => void;
}

export function getPagesRange(page: number, total: number, neighboursCount = 2): number[] {
  const PAGINATOR_RANGE = neighboursCount * 2;

  if (total <= PAGINATOR_RANGE) {
    // Total amount of pages are less than the possible paginator range
    return range(0, total);
  }
  if (page <= neighboursCount) {
    // Active page is at the start of the paginator page count
    return range(0, PAGINATOR_RANGE + 1);
  }
  if (page > total - neighboursCount) {
    // Active page is at the end of the paginator page count
    return range(total - PAGINATOR_RANGE - 1, total);
  }
  // Active page is in the middle of the paginator count
  return range(page - neighboursCount - 1, page + neighboursCount);
}

export function Pagination(props: Props) {
  const { itemsPerPage, totalItems, onPageChange } = props;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const hasOnlyOnePage = totalPages === 1;
  const activePage = clamp(props.activePage, 1, totalPages);
  const activePageIsAtPaginatorStart = activePage === 1;
  const activePageIsAtPaginatorEnd = activePage === totalPages;

  return (
    <div data-test-id="pagination" style={{ textAlign: 'center' }}>
      <Button
        buttonType="naked"
        disabled={hasOnlyOnePage || activePageIsAtPaginatorStart}
        onClick={() => onPageChange(1)}>
        <img src={doubleChevron} alt="To beginning" className="paginator-buttons-left" />
      </Button>
      <Button
        aria-label="To previous page"
        icon="ChevronLeft"
        buttonType="naked"
        disabled={hasOnlyOnePage || activePageIsAtPaginatorStart}
        onClick={() => onPageChange(activePage - 1)}
      />
      {getPagesRange(activePage, totalPages).map((pageIndex) => {
        const page = pageIndex + 1;
        return (
          <Button
            onClick={() => onPageChange(page)}
            buttonType={page === activePage ? 'primary' : 'naked'}
            testId={page === activePage ? 'active' : `inactive-${page}`}
            key={pageIndex}>
            {page}
          </Button>
        );
      })}
      <Button
        icon="ChevronRight"
        buttonType="naked"
        disabled={hasOnlyOnePage || activePageIsAtPaginatorEnd}
        onClick={() => onPageChange(activePage + 1)}
        aria-label="To next page"
      />
      <Button
        buttonType="naked"
        disabled={hasOnlyOnePage || activePageIsAtPaginatorEnd}
        onClick={() => onPageChange(totalPages)}>
        <img src={doubleChevron} alt="To last" className="paginator-buttons-right" />
      </Button>
    </div>
  );
}
