import {
  Dropdown,
  DropdownList,
  DropdownListItem,
  Paragraph,
  Typography,
} from '@contentful/forma-36-react-components';
import { noop } from 'lodash';
import React from 'react';
import { StateColor } from '../../model/StateColor';
import { SelectedWorkflowState } from '../Sidebar/SelectedWorkflowState';
import { styles as sidebarStyles } from '../Sidebar/styles';
import { styles } from './styles';

export function Preview() {
  return (
    <div className={styles.preview}>
      <Dropdown
        isFullWidth
        isOpen
        className={sidebarStyles.sidebarDropdown}
        toggleElement={
          <SelectedWorkflowState
            toggleMenuOpen={noop}
            menuOpen
            workflowState={{
              id: 'in-review',
              color: StateColor.Red,
              name: 'in review',
              description: 'Ready for marcom review.',
            }}
            isBlankState={false}
            isLoadingState={false}
          />
        }>
        <DropdownList>
          <DropdownListItem onClick={noop}>
            <div className={sidebarStyles.sidebarInfo}>
              <div
                className={sidebarStyles.stateColor}
                style={{ backgroundColor: StateColor.Purple }}
              />
              <Typography className={sidebarStyles.nameDescription}>
                <Paragraph>In legal review</Paragraph>
                <Paragraph>Ready for review by the legal team.</Paragraph>
              </Typography>
            </div>
          </DropdownListItem>
          <DropdownListItem onClick={noop}>
            <div className={sidebarStyles.sidebarInfo}>
              <div
                className={sidebarStyles.stateColor}
                style={{ backgroundColor: StateColor.Green }}
              />
              <Typography className={sidebarStyles.nameDescription}>
                <Paragraph>Approved</Paragraph>
                <Paragraph>Approved and ready to be published.</Paragraph>
              </Typography>
            </div>
          </DropdownListItem>
        </DropdownList>
      </Dropdown>
    </div>
  );
}
