const LOG_LEVEL = Number.parseInt(process.env.REACT_APP_LOG_LEVEL ?? '5', 10)
const LOG_ENABLED = process.env.NODE_ENV !== 'production'

enum LogLevel {
  Trace,
  Debug,
  Info,
  Warning,
  Error,
  Fatal
}

const shouldLog = (level: number) => {
  return LOG_ENABLED && LOG_LEVEL >= level
}

export const debug = (...args: any[]) => {
  shouldLog(LogLevel.Debug) && console.log(...args)
}

export const info = (...args: any[]) => {
  shouldLog(LogLevel.Info) && console.info(...args)
}

export const warning = (...args: any[]) => {
  shouldLog(LogLevel.Warning) && console.warn(...args)
}

export const error = (...args: any[]) => {
  shouldLog(LogLevel.Error) && console.error(...args)
}