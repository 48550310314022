import React, { useEffect } from 'react';
import { TextField, Button, ToggleButton } from '@contentful/forma-36-react-components';
import './styles.scss';
import { StateColor } from '../../model/StateColor';
import { WorkflowState, WorkflowStateId } from '../../model/WorkflowState';

interface Props {
  workflowState: WorkflowState;
  updateWorkflowState: (workflowState: WorkflowState) => void;
  onSave: (workflowId: WorkflowStateId) => void;
  onCancel: () => void;
}

type HTMLEvent = React.ChangeEvent<HTMLInputElement>;

function modifyWorkflowStateProp(
  value: string,
  updateWorkflowState: Props['updateWorkflowState'],
  workflowState: WorkflowState,
  prop: keyof WorkflowState
) {
  return updateWorkflowState({
    ...workflowState,
    [prop]: value.trim(),
  });
}

const nameInputId = 'nameInput';

export function ModifyState({ workflowState, updateWorkflowState, onSave, onCancel }: Props) {
  useEffect(() => {
    // hack: f36 doesn't allow for grabbing the underlying ref of our name input
    // so we need to grab the actual element.
    document.getElementById(nameInputId)?.focus();
  }, []);

  return (
    <div className="modify-dialog">
      <TextField
        helpText=""
        id={nameInputId}
        labelText="Name"
        name="Name"
        onChange={(e: HTMLEvent) =>
          modifyWorkflowStateProp(e.target.value, updateWorkflowState, workflowState, 'name')
        }
        required
        textInputProps={{
          disabled: false,
          maxLength: 100,
          placeholder: 'Name your workflow state',
          type: 'text',
        }}
        value={workflowState.name}
        width="full"
        className="input-section"
      />
      {Object.entries(StateColor).map(([id, colorValue]) => (
        <ToggleButton
          isActive={workflowState.color === colorValue}
          key={id}
          className="color-picker"
          onToggle={() =>
            modifyWorkflowStateProp(colorValue, updateWorkflowState, workflowState, 'color')
          }>
          <div style={{ backgroundColor: colorValue, borderRadius: '2px' }} />
        </ToggleButton>
      ))}
      <TextField
        helpText=""
        id="emailInput"
        labelText="Description"
        name="Description"
        onChange={(e: HTMLEvent) =>
          modifyWorkflowStateProp(e.target.value, updateWorkflowState, workflowState, 'description')
        }
        textInputProps={{
          disabled: false,
          maxLength: 100,
          placeholder: 'Help editors better understand this state',
          type: 'text',
        }}
        value={workflowState.description}
        width="full"
        className="input-section"
      />
      <div className="button-section">
        <Button
          buttonType="positive"
          onClick={() => onSave(workflowState.id)}
          disabled={workflowState.name.length === 0}>
          Save
        </Button>
        <Button buttonType="muted" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
}
