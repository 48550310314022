import { nanoid } from 'nanoid';
import { StateColor } from '../model/StateColor';
import { buildWorkflowStateId, WorkflowState } from '../model/WorkflowState';
import { Workflow } from '../model/Workflow';

export function buildBlankWorkflow(name = ''): Workflow {
  return {
    id: nanoid(),
    name,
    states: [],
  };
}

export function buildWorkflowState(
  name: string,
  description: string,
  color = StateColor.DarkBlue
): WorkflowState {
  const id = buildWorkflowStateId();
  return {
    id,
    name,
    description,
    color,
  };
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}
