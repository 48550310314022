import React, { useEffect, useState } from 'react';
import { useImmer } from 'use-immer';
import { DialogExtensionSDK } from '@contentful/app-sdk';

import { ModifyState, ListStates, DialogResponse, DialogParams } from './Dialogs';
import { buildWorkflowState } from './util';
import { WorkflowState, WorkflowStateId } from '../model/WorkflowState';
import { WorkflowStatesRecord } from '../model/ApplicationState';
import { Workflow } from '../model/Workflow';

interface Props {
  sdk: DialogExtensionSDK;
}

function buildDialogResponse(
  addedWorkflowId: WorkflowStateId,
  workflowStates: WorkflowStatesRecord
): DialogResponse {
  return {
    addedWorkflowId,
    workflowStates,
  };
}

function getUsedWorkflowStateIds(
  workflowStates: WorkflowStatesRecord,
  workflow: Workflow
): WorkflowStateId[] {
  return Object.keys(workflowStates).filter((wfsId) => workflow.states.includes(wfsId));
}

function determineInitialSelectedWorkflowStateId(
  hasUnusedWorkflowStates: boolean,
  blankWorkflowStateId: WorkflowStateId,
  userSelectedWorkflowStateId?: WorkflowStateId
) {
  // order of these checks is important
  if (userSelectedWorkflowStateId) {
    return userSelectedWorkflowStateId;
  }

  if (hasUnusedWorkflowStates) {
    return '';
  }

  return blankWorkflowStateId;
}

const Dialog = ({ sdk }: Props) => {
  const params = sdk.parameters.invocation as DialogParams;
  const usedWorkflowStateIds = getUsedWorkflowStateIds(params.workflowStates, params.workflow);
  const hasUnusedWorkflowStates =
    Object.keys(params.workflowStates).filter((wfsId) => !usedWorkflowStateIds.includes(wfsId))
      .length > 0;
  const blankWorkflowState = buildWorkflowState('', '');

  useEffect(() => {
    sdk.window.startAutoResizer();
  });

  const [workflowStates, updateWorkflowStates] = useImmer(
    params.selectedWorkflowStateId || hasUnusedWorkflowStates
      ? params.workflowStates
      : { ...params.workflowStates, [blankWorkflowState.id]: blankWorkflowState }
  );

  const [selectedWorkflowStateId, setSelectedWorkflowState] = useState(
    determineInitialSelectedWorkflowStateId(
      hasUnusedWorkflowStates,
      blankWorkflowState.id,
      params.selectedWorkflowStateId
    )
  );

  const updateSingleState = (workflowState: WorkflowState) =>
    updateWorkflowStates((draft) => {
      draft[workflowState.id] = workflowState;
    });

  const removeWorkflowState = (workflowStateId: WorkflowStateId) => {
    const updatedStates: WorkflowStatesRecord = {
      ...workflowStates,
    };

    delete updatedStates[workflowStateId];

    sdk.close(buildDialogResponse('', updatedStates));
  };

  if (selectedWorkflowStateId) {
    return (
      <div className="dialog">
        <ModifyState
          workflowState={workflowStates[selectedWorkflowStateId]}
          updateWorkflowState={updateSingleState}
          onSave={(id) => sdk.close(buildDialogResponse(id, workflowStates))}
          onCancel={() => sdk.close()}
        />
      </div>
    );
  } else {
    return (
      <ListStates
        workflowStates={workflowStates}
        selectWorkflowState={(id) => sdk.close(buildDialogResponse(id, workflowStates))}
        addNewWorkflow={() => {
          updateSingleState(blankWorkflowState);
          setSelectedWorkflowState(blankWorkflowState.id);
        }}
        editWorkflow={(id) => setSelectedWorkflowState(id)}
        removeWorkflowState={(wfsId) => removeWorkflowState(wfsId)}
        disabledWorkflowIds={getUsedWorkflowStateIds(workflowStates, params.workflow)}
      />
    );
  }
};

export default Dialog;
