import { WorkflowStateId } from './WorkflowState';

export interface Workflow {
  id: string;
  name: string;
  states: WorkflowStateId[];
}

export type WorkflowId = Workflow['id'];

export const isInvalidWorkflow = (workflow: Workflow) => {
  return !workflow.name;
};
