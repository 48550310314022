import React, { Component } from 'react';
import { Typography, Paragraph, Heading, Icon } from '@contentful/forma-36-react-components';
import { AppExtensionSDK } from '@contentful/app-sdk';
import { PreInstallScreen } from '../PreInstallScreen';
import { WorkflowsConfig } from '../WorkflowsConfig';
import WorkflowIcon from '../../assets/icon.svg';
import { Workflow, WorkflowId } from '../../model/Workflow';
import { ApplicationState, WorkflowStatesRecord } from '../../model/ApplicationState';
import { ContentType, ContentTypeId } from '../../model/ContentType';

interface ConfigProps {
  sdk: AppExtensionSDK; // Ideally this should only live in the Container
  parameters: ApplicationState;
  isInstalled: boolean;
  checkedEAPTerms: boolean;
  contentTypes: ContentType[];
  unsavedChanges: boolean;
  onAfterConfig(): Promise<void>;
  onSave(): Promise<false | { parameters: ApplicationState }>;
  updateWorkflow(workflow: Workflow): Promise<void>;
  updateWorkflowStates(workflowStates: WorkflowStatesRecord): Promise<void>;
  toggleContentTypeSelection(contentTypeId: ContentTypeId, workflowId: WorkflowId): Promise<void>;
  onChangeEAPTerms(): void;
}

export default class Config extends Component<ConfigProps> {
  render() {
    const { props } = this;

    let body;

    if (!props.isInstalled) {
      body = (
        <PreInstallScreen
          onChangeEAPTerms={props.onChangeEAPTerms}
          EAPTerms={props.checkedEAPTerms}
        />
      );
    } else {
      body = (
        <WorkflowsConfig
          workflows={props.parameters.workflowDefinitions}
          workflowStates={props.parameters.workflowStates}
          updateWorkflowStates={props.updateWorkflowStates}
          updateWorkflow={props.updateWorkflow}
          contentTypes={props.contentTypes}
          openDialog={props.sdk.dialogs.openCurrentApp}
          toggleContentTypeSelection={props.toggleContentTypeSelection}
          workflowInstances={props.parameters.workflowInstances}
        />
      );
    }

    return (
      <div className="app">
        <div
          className={['unsaved']
            .concat([props.isInstalled && props.unsavedChanges ? 'shown' : ''])
            .join(' ')}>
          <Paragraph>
            <Icon icon="InfoCircleTrimmed" color="white" />
            You have unsaved changes.
          </Paragraph>
        </div>
        <div className="background" />
        <div className="title">
          <Typography className="title">
            <Heading>Workflows</Heading>
            <Paragraph>Create workflows with custom states for your content.</Paragraph>
          </Typography>
        </div>
        {body}
        <div className="logo">
          <img src={WorkflowIcon} alt="" />
        </div>
      </div>
    );
  }
}
