import React, { useState, useEffect, useMemo } from 'react';
import { PageExtensionSDK, CollectionResponse } from '@contentful/app-sdk';
import { EntryRepository } from '../../data/EntryRepository';
import { ContentTypeRepository } from '../../data/ContentTypeRepository';
import WorkflowsDashboard from './WorkflowsDashboard';
import {
  buildWorkflowStateEntry,
  APIEntry,
  WorkflowStateEntryRecord,
  getEntryStatus,
} from '../../model/Entry';
import { ApplicationState } from '../../model/ApplicationState';
import { UserRepository } from '../../data/UserRepository';
import { NUMBER_OF_ENTITIES_PER_VIEW } from '../Pagination/constants';
import { ALL_WORKFLOW_STATE_ID, WorkflowStateId } from '../../model/WorkflowState';
import { createInitialState } from './utils';
import * as logger from '../../logger';

interface Props {
  sdk: PageExtensionSDK;
}

export default function WorkflowsDashboardContainer({ sdk }: Props) {
  const userRepository = new UserRepository(sdk);
  const entryRepository = new EntryRepository(sdk);
  const contentTypeRepository = new ContentTypeRepository(sdk);
  const currentUser = sdk.user;

  const { workflowStates, workflowDefinitions } = sdk.parameters.installation as ApplicationState;

  const [activeWorkflowState, setActiveWorkflowState] = useState(ALL_WORKFLOW_STATE_ID);
  const [activePage, setActivePage] = useState(1);
  const [loading, setIsLoading] = useState(true);
  const [entriesLoading, setEntriesLoading] = useState(false);
  const [entriesByWorkflowState, setEntriesByWorkflowState] = useState<WorkflowStateEntryRecord>(
    createInitialState()
  );

  // Select only states actually used by a workflows definition
  // This yields an ordered list of states.
  // When there is more than one state, the order wil be
  // [...workflowStatesOfFirstWorkflow, ...workflowStatesOfSecondWorkflow, ...]
  const usedWorkflowStateIds = useMemo(
    () =>
      Object.values(workflowDefinitions).reduce((accumulator, definition) => {
        accumulator.push(...definition.states);

        return accumulator;
      }, [] as WorkflowStateId[]),
    [workflowDefinitions]
  );

  const updateWorkflowStateEntry = (newWorkflowStateEntry: WorkflowStateEntryRecord) => {
    setEntriesByWorkflowState(previousState => {
      return {
        ...previousState,
        ...newWorkflowStateEntry,
      };
    });
  };

  const handlePageChange = async (page: number, workflowStateId: string) => {
    if (activePage === page) {
      return;
    }
    setEntriesLoading(true);
    const entitiesToSkip = (page - 1) * NUMBER_OF_ENTITIES_PER_VIEW;

    try {
      setActivePage(page);
      const allUsersOfSpace = await userRepository.query();
      const allContentTypesOfSpace = await contentTypeRepository.query();
      let result: CollectionResponse<APIEntry>;
      if (workflowStateId === ALL_WORKFLOW_STATE_ID) {
        result = await entryRepository.readByWorkflowIds(usedWorkflowStateIds, {
          skip: entitiesToSkip,
        });
      } else {
        result = await entryRepository.readByWorkflowIds([workflowStateId], {
          skip: entitiesToSkip,
        });
      }

      const newWorkflowEntryState = buildWorkflowStateEntry(
        currentUser,
        result,
        allUsersOfSpace,
        allContentTypesOfSpace,
        workflowStates[workflowStateId]
      );
      updateWorkflowStateEntry(newWorkflowEntryState);
    } catch (error) {
      setActivePage(1);
      logger.error('Unable to fetch new entries. Please try again', error);
    } finally {
      setEntriesLoading(false);
    }
  };

  useEffect(() => {
    const fetchEntries = async () => {
      const entriesByWorkflowStateIdToFetch = usedWorkflowStateIds.map(workflowStateId =>
        entryRepository
          .readByWorkflowIds([workflowStateId])
          .then(entries => ({ entries, id: workflowStateId }))
      );
      const allEntriesWithWorkflowState = entryRepository
        .readByWorkflowIds(usedWorkflowStateIds)
        .then(entries => ({ entries, id: ALL_WORKFLOW_STATE_ID }));

      try {
        const allUsersOfSpace = await userRepository.query();
        const allContentTypesOfSpace = await contentTypeRepository.query();

        const result = await Promise.all([
          ...entriesByWorkflowStateIdToFetch,
          allEntriesWithWorkflowState,
        ]);

        const workflowStateEntry = result.reduce(
          (workflowStateEntryRecords, currentEntryRecord) => {
            const newWorkFlowStateEntryRecord = buildWorkflowStateEntry(
              currentUser,
              currentEntryRecord.entries,
              allUsersOfSpace,
              allContentTypesOfSpace,
              workflowStates[currentEntryRecord.id]
            );
            return {
              ...workflowStateEntryRecords,
              ...newWorkFlowStateEntryRecord,
            };
          },
          {}
        );

        updateWorkflowStateEntry(workflowStateEntry);
      } catch (error) {
        logger.error('Could not fetch the entries. Please try again.', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchEntries();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <WorkflowsDashboard
        handlePageChange={handlePageChange}
        activePage={activePage}
        workflowStates={entriesByWorkflowState}
        workflowStateIds={usedWorkflowStateIds}
        loading={loading}
        entriesLoading={entriesLoading}
        getEntryStatus={getEntryStatus}
        setActiveWorkflowState={(workflowStateId: string) => {
          setActiveWorkflowState(workflowStateId);
          setActivePage(1);
        }}
        activeWorkflowState={activeWorkflowState}
        openEntry={id => sdk.navigator.openEntry(id)}
      />
    </div>
  );
}
