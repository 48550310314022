import * as React from 'react';
import { Typography, Paragraph } from '@contentful/forma-36-react-components';
import tokens from '@contentful/forma-36-tokens';
import './styles.scss';
import { StateColor, InternalStateColor } from '../../model/StateColor';

export interface Props {
  onClick?: () => void;
  isActive: boolean;
  name: string;
  color?: StateColor | InternalStateColor;
  amount: number;
}

export function WorkflowSidebarItem({ onClick = () => {}, isActive, name, color, amount }: Props) {
  return (
    <div
      className="state-wrapper-item"
      style={{ backgroundColor: isActive ? tokens.colorElementLight : '' }}
      data-test-id="sidebar-item"
      onClick={onClick}>
      <div className="state-item">
        {color ? (
          <div
            className="state-color"
            data-test-id="state-color"
            style={{ backgroundColor: color }}
          />
        ) : null}
        <Typography>
          <Paragraph
            style={{
              fontWeight: isActive
                ? Number(tokens.fontWeightDemiBold)
                : Number(tokens.fontWeightNormal),
            }}>
            {name}
          </Paragraph>
        </Typography>
      </div>
      <Typography className="state-counter">
        <Paragraph>{amount}</Paragraph>
      </Typography>
    </div>
  );
}
