import React from 'react';
import { Button, Modal } from '@contentful/forma-36-react-components';
import tokens from '@contentful/forma-36-tokens';
import { WorkflowStateItem } from '../WorkflowStateItem';
import { WorkflowStatesRecord } from '../../model/ApplicationState';
import { WorkflowStateId } from '../../model/WorkflowState';
import { css } from 'emotion';

interface Props {
  workflowStates: WorkflowStatesRecord;
  selectWorkflowState: (workflowId: WorkflowStateId) => void;
  addNewWorkflow: () => void;
  editWorkflow: (workflowIdState: WorkflowStateId) => void;
  removeWorkflowState: (workflowStateId: WorkflowStateId) => void;
  disabledWorkflowIds?: WorkflowStateId[];
}

const styles = {
  modalContent: css({ marginBottom: '40px' }),

  modalControl: css({
    position: 'fixed',
    bottom: '0',
    width: '100%',
    background: tokens.colorWhite,
  }),
};

export function ListStates({
  workflowStates,
  selectWorkflowState,
  addNewWorkflow,
  editWorkflow,
  disabledWorkflowIds = [],
  removeWorkflowState,
}: Props) {
  return (
    <>
      <Modal.Content className={styles.modalContent}>
        {Object.values(workflowStates)
          .filter((wfs) => !disabledWorkflowIds.includes(wfs.id))
          .map((wfs) => (
            <WorkflowStateItem
              key={wfs.id}
              onClick={selectWorkflowState}
              workflowState={wfs}
              editWorkflowState={editWorkflow}
              removeWorkflowState={removeWorkflowState}
              removeLabel="Delete"
            />
          ))}
      </Modal.Content>
      <Modal.Controls className={styles.modalControl}>
        <Button buttonType="muted" onClick={addNewWorkflow} icon="Plus">
          Add State
        </Button>
      </Modal.Controls>
    </>
  );
}
