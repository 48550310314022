import { StateColor, InternalStateColor } from './StateColor';
import { Tag } from './Tag';
import { nanoid } from 'nanoid';

export interface WorkflowState {
  id: string;
  name: string;
  description: string;
  color: StateColor | InternalStateColor;
  tag?: Tag;
}

export const WorkflowStateIdPrefix = 'ctf-workflows-tag';
const WorkflowStateNamePrefix = 'Workflows: ';
export type WorkflowStateId = WorkflowState['id'];

export const hydrateWorkflowStateWithTag = (workflowState: WorkflowState, tag: Tag) => {
  return {
    ...workflowState,
    id: tag.sys.id,
    name: unprefixWorkflowStateName(tag.name),
    tag: tag,
  };
};

export const isWorkflowStateNew = (workflowState: WorkflowState) => !workflowState.tag;

export const buildWorkflowStateId = (id?: string) => {
  if (!id) {
    return `${WorkflowStateIdPrefix}-${nanoid()}`;
  }

  return id.startsWith(WorkflowStateIdPrefix) ? id : `${WorkflowStateIdPrefix}-${id}`;
};

// corresponding workflow state id
export const ALL_WORKFLOW_STATE_ID = 'all';

// Used for grouping all entries under an `All` workflow state
export const ALL_WORKFLOW_STATE: WorkflowState = {
  id: ALL_WORKFLOW_STATE_ID,
  description: 'All states',
  name: 'All',
  color: InternalStateColor.Blank,
};

export function prefixWorkflowStateName(name: WorkflowState['name']) {
  return `${WorkflowStateNamePrefix}${name}`;
}

export function unprefixWorkflowStateName(name: WorkflowState['name']) {
  return name.split(WorkflowStateNamePrefix)[1];
}
