import React, { useState } from 'react';
import {
  Typography,
  Paragraph,
  Card,
  Icon,
  IconButton,
  Dropdown,
  DropdownList,
  DropdownListItem,
} from '@contentful/forma-36-react-components';
import './styles.scss';
import { WorkflowState, WorkflowStateId } from '../../model/WorkflowState';

interface Props {
  workflowState: WorkflowState;
  showDragIcon?: boolean;
  editWorkflowState: (id: WorkflowStateId) => void;
  removeWorkflowState: (id: WorkflowStateId) => void;
  removeLabel: string;
  onClick?: (id: WorkflowStateId) => void;
}

export function WorkflowStateItem({
  workflowState,
  showDragIcon,
  editWorkflowState,
  removeWorkflowState,
  removeLabel,
  onClick = () => {},
}: Props) {
  const [dropdownOpen, toggleDropdown] = useState(false);

  return (
    <Card {...(showDragIcon ? {} : { onClick: () => onClick(workflowState.id) })} className="state">
      <div className="state-info" onClick={() => onClick(workflowState.id)}>
        {showDragIcon && <Icon icon="Drag" color="muted" />}
        <div className="state-color" style={{ backgroundColor: workflowState.color }} />
        <Typography>
          <Paragraph>{workflowState.name}</Paragraph>
        </Typography>
      </div>
      <div
        className="state-menu-icon"
        onClick={(e) => {
          // stop propagation to avoid triggering the onClick handler that may be attached to the card itself
          e.stopPropagation();

          toggleDropdown(!dropdownOpen);
        }}>
        <Dropdown
          onClose={() => toggleDropdown(false)}
          isOpen={dropdownOpen}
          toggleElement={
            <IconButton
              buttonType="muted"
              iconProps={{
                icon: 'MoreHorizontal',
              }}
              label="Add New Element"
            />
          }>
          <DropdownList>
            <DropdownListItem onClick={() => editWorkflowState(workflowState.id)}>
              Edit
            </DropdownListItem>
            <DropdownListItem onClick={() => removeWorkflowState(workflowState.id)}>
              {removeLabel}
            </DropdownListItem>
          </DropdownList>
        </Dropdown>
      </div>
    </Card>
  );
}
