import tokens from '@contentful/forma-36-tokens';
import { css } from 'emotion';
import { MAX_WORKFLOW_STATE_ITEM_HEIGHT } from './constants';

export const styles = {
  sidebar: css({
    padding: '3px', // leave space for the box-shadow
  }),

  sidebarDropdown: css({
    width: '100%',
  }),

  sidebarInfo: css({
    display: 'flex',
    maxHeight: `${MAX_WORKFLOW_STATE_ITEM_HEIGHT}px`,
    overflow: 'hidden',
  }),

  sidebarSelected: css({
    textAlign: 'left',
    height: 'auto',
    paddingTop: tokens.spacingS,
    paddingBottom: tokens.spacingS,

    'span span': {
      width: '100%' // Button__label
    }
  }),

  nameDescription: css({
    p: {
      margin: 0,
      '&:first-child': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        color: `${tokens.colorTextDark}`,
        fontWeight: 600,
      },
      '&:last-child': {
        color: `${tokens.colorTextLightest}`,
        whiteSpace: 'pre-wrap',
        overflowWrap: 'anywhere',
      },
    },
  }),

  stateColor: css({
    marginTop: '4px',
    borderRadius: '2px',
    width: '12px',
    height: '12px',
    marginRight: tokens.spacingXs,
  }),

  deprecationCopy: css({
    display: "inline-block",
    paddingLeft: '5px',
    verticalAlign: 'top',
  })
};
