export enum StateColor {
  DarkBlue = '#162441',
  Blue = '#4988f7',
  LightBlue = '#3fa9cf',
  Green = '#49a171',
  Yellow = '#f5ba3a',
  Red = '#e95037',
  Purple = '#4d49b0',
  Pink = '#b947be',
}

export enum InternalStateColor {
  Blank = '#d3dce0',
}
