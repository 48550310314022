import {
  Dropdown,
  DropdownList,
  DropdownListItem,
  HelpText,
  Icon,
  Paragraph,
  TextLink,
  Tooltip,
  Typography,
} from '@contentful/forma-36-react-components';
import React, { useState } from 'react';
import { WorkflowState, WorkflowStateId } from '../../model/WorkflowState';
import { buildWorkflowState } from '../util';
import { SelectedWorkflowState } from './SelectedWorkflowState';
import { styles } from './styles';

interface Props {
  selectedWorkflowStateId: WorkflowStateId;
  workflowStates: WorkflowState[];
  navigateToDashboard(): void;
  selectWorkflowState(wfsId: WorkflowStateId): void;
  spaceId: string;
  updateHeight(height?: number): void;
  isLoading: boolean;
}

const blankWorkflowState = buildWorkflowState('No State', 'Select a state to start workflow');

export const Sidebar = ({
  selectedWorkflowStateId,
  workflowStates,
  selectWorkflowState,
  spaceId,
  navigateToDashboard,
  updateHeight,
  isLoading,
}: Props) => {
  const [menuOpen, toggleMenuState] = useState(false);

  const toggleMenuOpen = (open: boolean) => {
    toggleMenuState(open);
  };

  let selectedWorkflowState = workflowStates.find((wfs) => wfs.id === selectedWorkflowStateId);

  const handleListContainerRefChange = (listElement: HTMLElement | null) => {
    if (!listElement || !menuOpen) {
      updateHeight();
    } else {
      const buttonHeight = document.documentElement.getBoundingClientRect().height;
      const listHeight = listElement.getBoundingClientRect().height;
      const buffer = 25; // for borders, shadows etc.
      updateHeight(buttonHeight + listHeight + buffer);
    }
  };

  if (!selectedWorkflowState) {
    selectedWorkflowState = blankWorkflowState;
  }

  return (
    <div className={styles.sidebar} test-id="sidebar">
      
      <Dropdown
        isFullWidth
        isOpen={menuOpen}
        onClose={() => toggleMenuOpen(false)}
        className={styles.sidebarDropdown}
        toggleElement={
          <SelectedWorkflowState
            toggleMenuOpen={toggleMenuOpen}
            menuOpen={menuOpen}
            workflowState={selectedWorkflowState}
            isBlankState={selectedWorkflowState.id === blankWorkflowState.id}
            isLoadingState={isLoading}
          />
        }
        getContainerRef={handleListContainerRefChange}>
        <DropdownList>
          {workflowStates
            .filter((wfs) => wfs.id !== selectedWorkflowStateId)
            .map((wfs) => (
              <DropdownListItem
                key={wfs.id}
                testId="workflow-dropdown-item"
                onClick={() => {
                  toggleMenuOpen(false);
                  selectWorkflowState(wfs.id);
                }}>
                <div className={styles.sidebarInfo}>
                  <div className={styles.stateColor} style={{ backgroundColor: wfs.color }} />
                  <Typography className={styles.nameDescription}>
                    <Paragraph>{wfs.name}</Paragraph>
                    <Paragraph>{wfs.description}</Paragraph>
                  </Typography>
                </div>
              </DropdownListItem>
            ))}
        </DropdownList>
        <DropdownList border="top">
          <DropdownListItem>
            <TextLink onClick={navigateToDashboard}>View workflows dashboard</TextLink>
          </DropdownListItem>
        </DropdownList>
      </Dropdown>
      <Tooltip
        place="top"
        id="tip1"
        targetWrapperClassName="targetWrapperClassName"
        content="As of 1 Jun 2023, this version of Workflows is no longer supported. You can continue using it at your own risk. Contact Contentful to upgrade to the new version"
      >
        <HelpText style={{ marginTop: '25px', lineHeight: '1.3'}}>
          <Icon color="primary" icon="InfoCircle" /><span className={styles.deprecationCopy}>Discontinued on 1 Jun 2023</span>
        </HelpText>
      </Tooltip>
    </div>
  );
};
