import { Repository } from './Repository';
import { User, PageExtensionSDK } from '@contentful/app-sdk';

export class UserRepository implements Partial<Repository<User>> {
  constructor(private appSdk: PageExtensionSDK) {}

  async query(): Promise<User[]> {
    const { items } = await this.appSdk.space.getUsers<User>();
    return items;
  }

  async read(entityId: string): Promise<User> {
    const allUsers = await this.query();
    const user = allUsers.find((user) => user.sys.id === entityId);
    if (!user) {
      throw new ReferenceError(`User ${entityId} is missing. Unable to proceed`);
    }
    return user;
  }
}
