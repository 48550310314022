import { Link } from '@contentful/app-sdk';

export type TagLink = Link;

export enum TagType {
  Default = 'Default',
  Access = 'Access',
}

export interface Tag {
  sys: {
    id: string;
    type: 'Tag';
    version: number;
  };
  name: string;
}

export const toLink = (tag: Tag): TagLink => {
  return {
    sys: {
      id: tag.sys.id,
      type: 'Link',
      linkType: 'Tag',
    },
  };
};
