import { makeArrayResponse } from '../__fixtures__/utilities';
import { NUMBER_OF_ENTITIES_PER_VIEW } from '../Pagination/constants';
import { WorkflowStateEntryRecord } from '../../model/Entry';

export const createInitialState = (): WorkflowStateEntryRecord => ({
  all: {
    name: 'All',
    id: 'all',
    entries: {
      ...makeArrayResponse([]),
      skip: 0,
      limit: NUMBER_OF_ENTITIES_PER_VIEW,
    },
  },
});
